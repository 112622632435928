import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Banner from "../components/Banner"
import ButtonPrimary from "../components/ButtonPrimary"
import SEO from "../components/seo"
import BurgerMenu from "../components/MobileNavigation"

import style from "../components/mobile-navi-style"

const errorPage = () => {
  const MenuWrapper = style
  return (
    <>
      <MenuWrapper className="d-lg-none">
        <BurgerMenu />
      </MenuWrapper>
      <Layout>
        <SEO title="404: Not found" />
        <Header>
          <Banner title="404">
            <AniLink fade to="/" className="btn-white">
              <ButtonPrimary inverted title="Back to home" />
            </AniLink>
          </Banner>
        </Header>
      </Layout>
    </>
  )
}

const Header = styled.div`
  background: var(--primaryColor);
  min-height: calc(100vh - 164px);
  display: flex;
  justify-content: center;
  align-items: center;
`

export default errorPage
