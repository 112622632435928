import React from "react"
import styled from "styled-components"

const ButtonPrimary = ({
  title,
  inverted,
  onClick,
  className = "junbutton",
}) => {
  return (
    <BannerButton className={className} inverted={inverted} onClick={onClick}>
      {title}
    </BannerButton>
  )
}

const BannerButton = styled.button`
  cursor: pointer;
  color: ${props => (props.inverted ? "black !important" : "white !important")};
  text-transform: uppercase;
  text-decoration: none;
  background-color: "transparent" !important;
  padding: ${props => (props.inverted ? "10px" : "20px")};
  border: 2px solid #ff8a00 !important;
  display: inline-block;
  transition: all 0.4s ease 0s;
  border-radius: 0.25rem;
  :hover {
    color: #ffffff !important;
    background: #ff8a00;
    border-color: #ff8a00 !important;
    transition: all 0.4s ease 0s;
  }
`

export default ButtonPrimary
